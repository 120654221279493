let state = {
  application: {
    notifications: [],
    version: false,
    applicationLoaded: false,
    wallet: false,
    name: false,
    //UI States
    routerLoaded: false,
    uiSidebarCollapse: false,
    uiDemoValues: false,
    uiListSearchTerm: '',
    uiView: 'grid',
    uiThemeDark: true,
    zhHolderBool: false,
    zhBalance: false,
    walletConnected: false,
    activeFarm: 'lincoin',
    walletConnectModal: false,
    postBodyByteLimit: 950000,
    postTextBytesMin: 16,
    postTextCharacterMin:10,
    postTitleCharacterLimit: 1000,
    postBodyCharacterLimit: 20000,
    forumTopics: {
      quantities:false,
      names:['General', 'Organization', 'Governance', 'Mining', 'Economics', 'Proposals']},

  },
  data: {},
  payload: false,
  wallet: false,
  holdings: { btc: false, eth: false },
  rates: false,
  snapshot: false,
  snapshotUser: false,
  test: false,
  forumProfile: false,
  forumPostsCache: false,
  forumPosts: {
    total: false,
    page: 0,
    paginationLimit: 50,
  },
  forumPostRepliesWatcherFlag: false,
  ens:{
    name: false,
    avatar: false,
    systemUseENS: false,
  },
  physical_assets: false,
}
export default state