<template lang="html">
  <div class="parent-flex" v-if="!preset">
    <template v-if="short">
      <div v-for="n in 3" class="loading short" :key="'' + n"></div>
    </template>
    <template v-else>
      <div v-for="n in 3" class="loading small" :key="'small-' + n"></div>
      <div v-for="n in 2" class="loading" :key="'' + n"></div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'LoadingEle',
  props: ['preset', 'short'],
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/constants';

.parent-flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 30px 0;

  .loading {
    align-content: start;
    // box-shadow: 0 0 0 0px var(--neutral-10);
    // transform: translateY(20px)scale(.9);
    border-radius: 15px;
    background-color: var(--neutral-10);
    max-width: unset;
    min-width: unset;
    height: 100px;
    width: 100%;
    min-height: 20vh;
    position: relative;
    overflow: hidden;

    &.short {
      height: 50px;
      min-height:unset;
    }

    &:after {
      content: '';
      background-image: linear-gradient(90deg, transparent, var(--neutral-7), transparent);
      position: absolute;
      height: 100%;
      width: 200%;
      top: 0;
      left: -50%;
      z-index: 2;
      opacity: .3;
      animation: loading 1000ms ease forwards infinite;

    }

    @media (max-width: $content) {
      width: calc(((100vw - 80px) / 3) - 20px);
    }

    @media (max-width: $medium) {
      width: calc(((100vw - 80px) / 2) - 20px);
    }

    @media (max-width: $small) {
      width: calc(((100vw - 80px) / 2) - 20px);
    }

    @media (max-width: $x-small) {
      width: calc(((100vw - 80px)) - 20px);
    }

    &.small {
      width: calc(25% - 15px);

      @media (max-width: $content) {
        width: calc(25% - 20px);
      }

      @media (max-width: $medium) {
        width: calc(33% - 15px);
      }

      @media (max-width: $small) {
        width: calc(50% - 10px);
      }

      @media (max-width: $x-small) {
        width: 100%;
      }
    }

    @keyframes loading {
      // 0% {
      //   opacity: .5
      // }

      // 100% {
      //   opacity: 1
      // }
      @for $i from 0 through 100 {
        #{$i * 1%} {
          transform: translateX(#{$i * 1%});
        }
      }
    }



  }
}
</style>